<template>
  <div class="questionnaire">
    <!-- <div class="banner">
      <img :src="require('@/assets/images/q-banner.png')" alt="">
    </div> -->
    <van-button @click="$router.back()" style="margin: 10px" :style="{ background: $store.state.themeInfo.themeColor, color: '#fff', borderRadius: '3px' }">返回</van-button>
    <div v-if="question.status === 2" class="q-main">
      <div class="title">{{ question.title }}</div>
      <div class="content" v-if="$route.params.id === '050a6644946abe7a492c67587fb6357c'">
        尊敬的参会嘉宾，为配合长沙市新型冠状病毒肺炎防控指 挥部，全力抗击疫情，做好疫情防控工作，峰会将对参会嘉宾
        采取相应防控措施。为保障现场秩序，并节约您的宝贵时间， 会务组温馨提示您如实填写下表，确保大会的顺利召开。感谢
        您的配合！
      </div>
      <div class="des">{{ question.intro }}</div>
      <div class="q-list">
        <el-form label-position="top" ref="ruleForm" :model="form">
          <el-form-item
            v-for="(item, i) in list"
            :key="item.id"
            :label="i + 1 + '.' + item.title"
            :rules="
              item.title === '手机号'
                ? [{ required: true, message: '请填写正确手机号', pattern: phoneRules, trigger: 'onChange' }]
                : []
            "
          >
            <template v-if="item.title === '家庭住址'">
              <div @click="openAreaPop">
                <el-input :placeholder="getHolderText(i, item.title)" v-model="form[item.title]" disabled />
              </div>
            </template>
            <template v-else>
              <el-input
                @input="phoneChange"
                :class="[item.title === '手机号' && phoneCheck ? 'border-red-redius' : '']"
                v-if="item.type === 'input' && item.title === '手机号'"
                :placeholder="getHolderText(i, item.title)"
                v-model="form[item.title]"
              />
              <el-input
                v-else-if="item.type === 'input'"
                :placeholder="getHolderText(i, item.title)"
                v-model="form[item.title]"
              />
              <div v-if="item.title === '手机号' && phoneCheck" style="color: red">手机号格式不正确</div>
              <el-input v-else-if="item.type === 'textarea'" type="textarea" v-model="form[item.title]" />
              <el-radio-group v-else-if="item.type === 'radio'" v-model="form[item.title]">
                <el-radio
                  v-for="child in item.values"
                  :key="child.id"
                  :label="child.label"
                  :value="item.label"
                ></el-radio>
              </el-radio-group>
              <el-checkbox-group v-else-if="item.type === 'checkbox'" v-model="form[item.title]">
                <el-checkbox
                  v-for="child in item.values"
                  :key="child.id"
                  :label="child.label"
                  :value="item.label"
                ></el-checkbox>
              </el-checkbox-group>

              <template v-else-if="item.type === 'datePicker'">
                <el-date-picker
                  style="width: 100%"
                  v-model="form[item.title]"
                  type="date"
                  valueFormat="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </template>
              <template v-else-if="item.type === 'uploadImg'">
                <uploadImg :url.sync="form[item.title]" :count="1" />
              </template>
              <!-- 签名 -->
              <template v-else-if="item.type === 'canvas'">
                <div class="sign">
                  <div class="sub-title">请签名</div>
                  <vue-esign class="esign" ref="gen" :height="600"></vue-esign>
                  <div class="icon" @click="reloadSign">
                    <van-icon name="replay" :size="30" />
                  </div>
                </div>
              </template>
            </template>
          </el-form-item>
        </el-form>
        <div v-if="list.length > 0" class="btn-wrap">
          <van-button
            :style="{ background: $store.state.themeInfo.themeColor, color: '#fff', borderRadius: '3px' }"
            @click="signImageHandler"
            >立即提交</van-button
          >
        </div>
        <!-- <div class="report">举报</div> -->
      </div>
    </div>
    <div v-if="question.status === 1" class="q-stop">此问卷未发布</div>
    <div v-if="question.status === 3" class="q-stop">此问卷已停用</div>

    <!-- 省市区 -->
    <van-popup v-model="showArea" position="bottom">
      <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="showArea = false" />
    </van-popup>
  </div>
</template>

<script>
import vueEsign from "vue-esign";
import { Toast, Dialog } from "vant";
import { areaList } from "@vant/area-data";
import uploadImg from "./components/uploadImg.vue";
export default {
  components: {
    uploadImg,
    vueEsign
  },
  data() {
    return {
      question: {
        title: "",
        intro: "",
        status: ""
      },
      list: [],
      form: {},
      rules: {},
      sendlock: false,
      showArea: false, // 展示省市区
      areaList: areaList,
      signOk: false,
      code: "",
      phoneRules:
        /^((\+|00)86)?((134\d{4})|((13[0-9]|14[1|5-9]|15[0-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[0-2|5-9])\d{8}))$/,
      phoneCheck: false,
      checked: false,
      signImage: ''
    };
  },
  created() {
    const user = this.$utils.getCookie("user");
    // const user = {
    //   openid: 'ogQs3w39cHZQmueTFbwcLD5lgmHs'
    // }
    if (!user || !user.openid) {
      if (!this.$route.query.code) {
        // window.location.href = this.$baseURL.weixin.getWxCode(window.location.href.split('#')[0])
        this.getWxCode();
        this.signOk = true;
        return;
      } else {
        this.signOk = true;
        this.code = this.$route.query.code;
        this.$http.postParams(this.$baseURL.weixin.userSign(), { code: this.code }, 1).then(res => {
          if (res.code === 200) {
            this.$utils.setCookie("user", JSON.stringify(res.data ? res.data : {}));
            this.$store.commit("userInfo/SET_USER", res.data);
          }
        });
      }
    } else {
      this.signOk = true;
      this.$store.commit("userInfo/SET_USER", user);
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    reloadSign() {
      this.$refs.gen[0].reset();
    },
    gen() {
      this.$refs.gen[0].generate().then(res => {
        this.resultImg = res;
      });
    },
    phoneChange(newVal) {
      if (newVal === "") {
        this.phoneCheck = false;
        return;
      }
      this.phoneCheck = true;
      if (this.phoneRules.test(newVal)) {
        this.phoneCheck = false;
      }
    },
    getWxCode() {
      window.location.href = this.$baseURL.weixin.getWxCode(window.location.href.split("#")[0]);
    },
    async getList() {
      let result = await this.$http.get(
        this.$baseURL.questionnaire.getQuestionInfo() +
          `?questionnaireId=${this.$route.params.id}&openId=${this.$store.state.userInfo.openid}`,
        1
      );
      if (result.code === 200) {
        this.loading = false;
        this.question.title = result.data.questions.title;
        this.question.status = result.data.questions.status;
        if (this.question.title) {
          document.title = this.question.title;
        }
        this.question.intro = result.data.questions.intro;
        const list = JSON.parse(result.data.questions.questions);
        if (result.data.answer) {
          const form = JSON.parse(result.data.answer.answerContent);
          list.forEach(item => {
            form.forEach(element => {
              if (item.id === element.id) {
                this.$set(this.form, item.title, element.answerValue);
              }
            });
          });
        } else {
          list.forEach(item => {
            if (item.type === "checkbox") {
              this.$set(this.form, item.title, []);
            } else {
              this.$set(this.form, item.title, "");
            }
          });
        }
        this.list = list;
        console.log(this.form, list);
      }
    },
    async signImageHandler() { 
      this.$refs.gen[0].generate().then((res) => {
        this.$http.post('/api/upload/uploadBase64', {base64Str: res}).then(res => {
          this.signImage = res
          this.form['本人承诺: 如实填写上述内容,自觉履行疫情防控的法律法规义务,承担相应的法律法规责任。'] = res;
          this.handleSubmit()
        })
      }).catch(() => {
        Toast('请签名')
      })
    },
    async handleSubmit() {
      if(this.signImage === '') {
        Toast('请签名')
        return
      }
      if (this.sendlock) {
        return;
      }
      this.sendlock = true;

      for (const key in this.form) {
        if (this.form[key] === "") {
          Toast(`${key} 不能为空`);
          this.sendlock = false;
          return;
        }
      }

      if (this.phoneCheck) {
        Toast("手机号格式不正确");
        return;
      }

      const list = [];
      this.list.forEach(item => {
        for (let key in this.form) {
          if (key === item.title) {
            list.push({ id: item.id, answerValue: this.form[key] });
          }
        }
      });
      let result = await this.$http.post(
        this.$baseURL.questionnaire.unlimitedAnswerQuestions(),
        {
          questionnaireId: this.$route.params.id,
          answerContent: JSON.stringify(list),
          openId: this.$store.state.userInfo.openid
        }, 1)
        console.log(result);
        if (result.code === 200) {
          const miceLink = this.$route.params.miceLink
          Toast('提交成功！')
          // 防疫问卷跳转个人中心
          if (this.$route.params.id==='050a6644946abe7a492c67587fb6357c') {
            this.$router.push('/user/' + miceLink)
          } else {
            this.$router.push('/question/result/' + miceLink)
          }
          this.sendlock = false
        }else {
          Toast(result.message)
          this.sendlock = false
          return false;
        }
      
    },
    getHolderText(i, title) {
      if (i === this.list.length - 1) {
        return "";
      } else {
        return `请输入${title}`;
      }
    },
    // 家庭住址省市区展示
    openAreaPop() {
      this.showArea = true;
    },
    onAreaConfirm(values) {
      this.form["家庭住址"] = values
        .filter(item => !!item)
        .map(item => item.name)
        .join("/");
      this.showArea = false;
    },
    beforeUpload(file) {
      if (file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "image/png") {
        Toast("文件类型不支持");
        return false;
      }
      const lt2M = file.size / 1024 / 1024 < 10;
      if (!lt2M) {
        Toast("文件大小不能超过10MB");
        return false;
      }
      return true;
    },
    async uploadImg(file, detail) {
      console.log(detail);
      file.status = "uploading";
      file.message = "上传中...";
      const data = new FormData();
      data.append("file", file.file);
      data.append("isFace", 0);
      this.uploadLoading = true;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1);
      this.uploadLoading = false;
      if (result.code === 200) {
        this.form[detail.name] = result.data;
        file.status = "success";
        file.message = "上传成功";
        file.url = result.data;
        Toast.clear();
      } else {
        this.faceImg = [];
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.questionnaire {
  font-size: 14px;
  .banner {
    img {
      width: 100%;
    }
  }
  .q-main {
    padding: 15px;
    .title {
      color: #1da1f0;
      font-size: 22px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #1da1f0;
      text-align: center;
    }
    .content {
      margin-top: 30px;
      font-size: 13px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #2b2a2a;
      text-indent: 2em;
    }
    .des {
      padding: 10px 0;
      margin-top: 20px;
      font-size: 18px;
      border-bottom: 1px solid #dadada;
      font-size: 15px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #eb0900;
      text-indent: 1em;
    }
    .q-list {
      padding: 15px 0;
      .btn-wrap {
        display: flex;
        justify-content: center;
        .van-button {
          width: 100%;
        }
      }
      .report {
        text-align: right;
        margin-top: 15px;
        font-size: 15px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #515151;
      }
    }
  }
}
.q-stop {
  margin: 20px 0;
  text-align: center;
}
/deep/ .q-list {
  label {
    display: block;
    margin: 0 0 10px;
    line-height: 1;
  }
}
/deep/ .el-form-item__label {
  font-size: 15px;
  color: #000;
}

.border-red-redius {
  border: 1px solid red;
  border-radius: 5px;

  & :focus {
    border: 0.02667rem solid #dcdfe6;
  }
}

.btn-wrap {
  margin-bottom: 10px;
}
.sign {
  border: 1px solid #ccc;
  position: relative;
  .sub-title {
    text-align: center;
    
  }
  .icon {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
}
</style>
