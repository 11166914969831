<template>
  <div>
    <van-uploader 
      v-model="imgList"
      :disabled="item.canEdit === 0"
      :deletable="item.canEdit !== 0"
      :max-count="count"
      :before-read="beforeUpload"
      :preview-options="{closeable:true, closeIconPosition:'top-left'}"
      :after-read="uploadImg"
      @delete="deleteImg"
      upload-icon="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202204141540452262137.png"
    />
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return { canEdit: 1 }
      }
    },
    url: {
      type: String
    },
    count: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      uploadLoading: false,
      imgList: []
    }
  },
  mounted() {
    if (this.url) {
      console.log(this.url)
      this.imgList = [
        { url: this.url }
      ]
    }
  },
  methods: {
    beforeUpload(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
        Toast('文件类型不支持')
        return false
      }
      const lt2M = file.size / 1024 / 1024 < 10
      if (!lt2M) {
        Toast('文件大小不能超过10MB')
          return false
      }
      return true
    },
    async uploadImg(file, detail) {
      console.log(detail)
      file.status = 'uploading'
      file.message = '上传中...'
      const data = new FormData()
      data.append('file', file.file)
      data.append('isFace', 0)
      this.uploadLoading = true
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1)
      this.uploadLoading = false
      if (result.code === 200) {
        this.$emit('update:url', result.data)
        file.status = 'success'
        file.message = '上传成功'
        file.url = result.data
        Toast.clear();
      } else {
        this.faceImg = []
        Dialog.alert({
          title: '提示',
          message: result.message,
        }).then(() => {
          // on close
        })
      }
    },
    deleteImg() {
      this.$emit('update:url', '')
    }
  }
}
</script>
<style>
</style>
